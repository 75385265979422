
































































import { Prop, Vue } from "vue-property-decorator";
import Component from "vue-class-component";
import { PropType } from "vue";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import Chart from "@/components/Charts/Chart.vue";
import { ChartData, ChartOptions } from "chart.js/auto";
import { IRegistrationsPerDrivingSchoolReport } from "@/interfaces/IRegistrationsPerDrivingSchoolReport";
import Hint from "@/views/AutomaticPaymentsOverview/Hint.vue";
import PrintButton from "@/components/Button/PrintButton.vue";
import DocumentButton from "@/components/Button/DocumentButton.vue";
import { formatInstructorName } from "@/utils/NameUtil";

@Component({
  methods: {
    formatInstructorName,
  },
  components: { Chart, FscSimpleCard, Hint, PrintButton, DocumentButton },
})
export default class ReportRegistrationChart extends Vue {
  public name = "ReportRegistrationChart";

  public chartColors = ["#169FDB", "#793b86", "#dc0c23", "#ea5b1b", "#f9b342"];

  @Prop({ type: Object as PropType<IRegistrationsPerDrivingSchoolReport> })
  public data!: IRegistrationsPerDrivingSchoolReport;

  @Prop({ type: Boolean })
  public loading!: boolean;

  @Prop()
  public filterInfo!: any;

  @Prop()
  public selectedType!: any;

  @Prop()
  public pieData!: any;

  public chartChanged = true;

  public get map() {
    if (!this.data) return [];
    const arr: any = [];
    for (const [key, value] of Object.entries(this.data)) {
      arr.push({
        x: key,
        y: value,
      });
    }
    return arr;
  }

  public get mapPie() {
    if (!this.pieData) return [];
    const arr: any = [];
    for (const [key, value] of Object.entries(this.pieData)) {
      arr.push(value);
    }
    return arr;
  }

  public get labelsPie() {
    if (!this.pieData) return [];
    const arr: any = [];
    for (const [key, value] of Object.entries(this.pieData)) {
      arr.push(key);
    }
    return arr;
  }

  public get chartData(): ChartData {
    return {
      datasets: [
        {
          fill: true,
          showLine: true,
          tension: 0.3,
          label: "",
          data: this.map,
          backgroundColor: "#169FDB",
          borderColor: "#793b86",
          pointBackgroundColor: this.chartColors,
          borderWidth: 1,
          borderRadius: 5,
        },
      ],
    };
  }

  public chartOptions: ChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },

    scales: {
      y: {
        ticks: {
          stepSize: 1,
          font: {
            weight: "bold",
            size: 10,
          },
        },
        beginAtZero: true,
      },
      x: {
        ticks: {
          font: {
            weight: "bold",
            size: 10,
          },
        },
      },
    },
  };

  public get chartPieData(): ChartData {
    return {
      labels: this.labelsPie,
      datasets: [
        {
          label: this.mapPie,
          data: this.mapPie,
          backgroundColor: this.chartColors,
          hoverOffset: 20,
          borderWidth: 3,
        },
      ],
    };
  }

  public chartPieOptions: ChartOptions<"doughnut"> = {
    responsive: true,
    radius: 160,
    aspectRatio: 2,
    maintainAspectRatio: true,
    layout: {
      padding: 50,
    },
    plugins: {
      legend: {
        display: true,
        position: "right",
      },
    },
  };
}
