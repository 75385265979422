
































































import { Component, Prop, Watch } from "vue-property-decorator";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import Datepicker from "@/components/Datepicker.vue";
import AbortButton from "@/components/Button/AbortButton.vue";
import SaveButton from "@/components/Button/SaveButton.vue";
import { IBasicNamedDTO } from "@/interfaces/IBasicNamedDTO";
import { PropType } from "vue";
import { IBranch } from "@/interfaces/IBranch";
import { IInstructor } from "@/interfaces/IInstructor";
import BaseMixin from "@/mixins/BaseMixin";
import { mixins } from "vue-class-component";
import Validation from "@/components/Validation.vue";
import { Validations } from "vuelidate-property-decorators";
import { required } from "vuelidate/lib/validators";
import { namespace } from "vuex-class";

const EducationEventModule = namespace("education-event");

@Component({
  components: {
    Validation,
    SaveButton,
    AbortButton,
    Datepicker,
    FscSimpleCard,
  },
})
export default class ReportPlanningFilter extends mixins(BaseMixin) {
  public name = "ReportPlanningFilter";

  @Prop({ type: Array as PropType<Array<IBasicNamedDTO>>, default: () => [] })
  public licenseClassGroups!: Array<IBasicNamedDTO>;

  @Prop({ type: Array as PropType<Array<IBranch>>, default: () => [] })
  public branches!: Array<IBranch>;

  @Prop({ type: Array as PropType<Array<IInstructor>>, default: () => [] })
  public instructors!: Array<IInstructor>;

  @Prop({ type: Array as PropType<Array<IBasicNamedDTO>>, default: () => [] })
  public paymentWorkFlows!: Array<IBasicNamedDTO>;

  @Prop({ type: Boolean, default: () => false })
  public showPaymentWorkflows!: boolean;

  @Prop({ type: Array, default: () => [] })
  public licenseClasses!: any;

  @Prop()
  public selectedRow!: any;

  @EducationEventModule.Action("findAll")
  public findAllMultiselectClasses: any;

  @EducationEventModule.Getter("getDataList")
  public allMultiselectClasses: any;

  public licenseClassMultiselect = false;

  public licenseClassGroupIds: any = null;
  public branchIds: any = null;
  public instructorIds: any = null;
  public startDate = null;
  public endDate = null;
  public paymentWorkflow: any = null;
  public licenseClassIds: any = null;
  public licenseClassesOptions: any = [];
  public licenseClassesMultiselectIds: any = null;

  private onSubmit(): void {
    this.$v.$touch();
    if (this.$v.$invalid) return;

    const filterInfo = {
      startDate: this.startDate,
      endDate: this.endDate,
      licenseClassGroupIds: this.licenseClassGroupIds,
      branchIds: this.branchIds,
      instructorIds: this.instructorIds,
      paymentWorkflow: this.paymentWorkflow ? this.paymentWorkflow : null,
      licenseClassIds: this.licenseClassIds,
      licenseClasses: this.licenseClassesMultiselectIds,
    };

    this.$emit(
      "on-submit",
      {
        licenseClassGroupIds: this.onLicenseClassGroupIds(),
        branchIds: this.onBranchIds(),
        instructorIds: this.onInstructorIds(),
        startDate: this.startDate,
        endDate: this.endDate,
        paymentWorkflow: this.paymentWorkflow,
        licenseClassIds: this.licenseClassIds,
        licenseClasses: this.onLicenseClassesMultiselectIds(),
      },
      filterInfo
    );
  }

  private onAbort(): void {
    this.$emit("on-close");
  }

  public branchLabel(branch: IBranch): string {
    return `${branch.postalCode} - ${branch.location}`;
  }

  public mounted() {
    this.reset();
  }
  @Validations()
  public validations(): Record<string, any> {
    return {
      startDate: {
        required,
      },
      endDate: {
        required,
      },
    };
  }

  public onLicenseClassGroupIds() {
    if (this.licenseClassGroupIds) {
      let ids = this.licenseClassGroupIds.map((licenseClass: any) => {
        return licenseClass.id;
      });
      return ids;
    }
    return null;
  }

  public onLicenseClassesMultiselectIds() {
    if (this.licenseClassesMultiselectIds) {
      let ids = this.licenseClassesMultiselectIds.map((licenseClass: any) => {
        return licenseClass.id;
      });
      return ids;
    }
    return null;
  }

  public onBranchIds() {
    if (this.branchIds) {
      let ids = this.branchIds.map((branch: any) => {
        return branch.id;
      });
      return ids;
    }
    return null;
  }

  public onInstructorIds() {
    if (this.instructorIds) {
      let ids = this.instructorIds.map((instructor: any) => {
        return instructor.id;
      });
      return ids;
    }
    return null;
  }

  public reset(): void {
    this.$v.$reset();
    this.licenseClassGroupIds = null;
    this.branchIds = null;
    this.instructorIds = null;
    this.startDate = null;
    this.endDate = null;
    this.paymentWorkflow = null;
    this.licenseClassIds = null;
  }

  @Watch("selectedRow", { immediate: true, deep: true })
  public async onSelectedRowChange(row: any): Promise<void> {
    if (row) {
      this.reset();
      this.startDate = null;
      this.endDate = null;
      this.licenseClassGroupIds = null;
      this.branchIds = null;
      this.instructorIds = null;
      this.paymentWorkflow = null;
      this.licenseClassIds = null;
    }
    if (row && row.id === 5) {
      this.licenseClassMultiselect = true;
    } else {
      this.licenseClassMultiselect = false;
    }
    switch (row.id) {
      case 2:
        await this.findAllMultiselectClasses({
          resource: `license-classes/for-export?exportType=3`,
        });
        this.licenseClassesOptions = this.allMultiselectClasses;
        break;
      default:
        console.log("DEFAULT");
    }
  }

  public get selectedRowIdType() {
    if (this.selectedRow.id === 2) {
      return true;
    }
    return false;
  }

  public get instructorField() {
    if (this.selectedRow.id === 2) {
      return false;
    }
    return true;
  }
}
